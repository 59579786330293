@use "../_globals/mixins";

/* Main container with border */

.form {
  word-wrap: break-word;
  background: #fff;
  border: mixins.rem(2px) solid #f5f5f5;
  border-radius: mixins.rem(10px);
  margin: 0 auto;
  margin-bottom: mixins.rem(24px);
  padding: mixins.rem(64px);
  line-height: mixins.rem(30px);
  box-shadow: 0 mixins.rem(25px) mixins.rem(50px) mixins.rem(-12px) rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  color: #000;

  @include mixins.mq(phone) {
    max-width: 80%;
    padding: 1rem;
    padding-bottom: 2rem;
  }

  @include mixins.theme(light) {
    color: #000;
  }

  &.component {
    margin-bottom: 6.5rem;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  a,
  ul,
  li {
    color: #000;
    text-align: left;

    @include mixins.theme(light) {
      color: #000;
    }
  }

  .small {
    font-size: mixins.rem(11.2px);
    line-height: mixins.rem(16px);
  }

  .Grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    margin: 0 auto;
    border-top: mixins.rem(0.08px) solid #ccc;
    padding: mixins.rem(8px);

    @include mixins.mq(s-phone) {
      width: 80% !important;
    }

    @include mixins.mq(phone) {
      width: 70% !important;
    }

    &:first-child {
      border-top: none;
    }
  }

  .switcher {
    width: 90% !important;

    input.switch {
      position: relative;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      width: mixins.rem(50px);
      height: mixins.rem(30px);
      background-color: #fff;
      border: mixins.rem(1.008px) solid #d9dadc;
      border-radius: mixins.rem(50px);
      box-shadow: inset mixins.rem(-20px) 0 0 0 #fff;

      &:after {
        content: "";
        position: absolute;
        top: mixins.rem(1.008px);
        left: mixins.rem(1.008px);
        background: transparent;
        width: mixins.rem(26px);
        height: mixins.rem(26px);
        border-radius: 50%;
        box-shadow: mixins.rem(2px) mixins.rem(4px) mixins.rem(6px) rgba(0, 0, 0, 0.2);
      }

      &:checked {
        box-shadow: inset mixins.rem(20px) 0 0 0 var(--button-bg-re);
        border-color: var(--button-bg-re);

        &:after {
          left: 1.25rem;
          box-shadow: mixins.rem(-2px) mixins.rem(4px) mixins.rem(3.008px) rgba(0, 0, 0, 0.05);
        }
      }
    }

    .description {
      width: 85% !important;
      flex: none !important;
      text-align: left;
      font-weight: var(--semibold-fontweight);

      .info {
        display: block;
        font-weight: 400;
        font-size: mixins.rem(14.4px);
        color: #595959;
        margin-top: 0;
        padding: 0;
        line-height: mixins.rem(16px);
      }
    }

    .icons {
      width: 15% !important;
      flex: none !important;
      font-weight: var(--semibold-fontweight);
      text-align: right;
    }
  }

  .links {
    color: #000;
    text-decoration: none;
    font-weight: var(--semibold-fontweight);
    width: 90% !important;
    cursor: pointer;
  }

  .Grid-cell {
    flex: 1;
    flex-direction: column;
    flex-basis: 100%;
  }

  .icons {
    width: 5% !important;
    flex: none !important;
    font-weight: var(--semibold-fontweight);
    text-align: right;
  }

  .description {
    width: 95% !important;
    flex: none !important;
    text-align: left;
  }

  .button {
    font-size: mixins.rem(11.2px);
    color: var(--button-fg-re);
    text-transform: uppercase;
    font-weight: bold;
    background: var(--button-bg-re);
    padding: mixins.rem(3.2px);
    padding-left: mixins.rem(16px);
    padding-right: mixins.rem(16px);
    border-radius: mixins.rem(48px);
    margin-top: mixins.rem(12.8px) !important;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
}