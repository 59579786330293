@use "../_globals/mixins";

.skeleton {
  display: block;
  width: fit-content;
  margin: 0 auto;
  background: #ccc;
  color: transparent;
  border-radius: 1.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0;
  cursor: default !important;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%) !important;
  background-size: mixins.rem(800px) mixins.rem(100px) !important;
  animation: skeleton 2s infinite ease-out;
}

@keyframes skeleton {
  0% {
    background-position: mixins.rem(-468px) 0;
  }

  100% {
    background-position: mixins.rem(468px) 0;
  }
}

.form {
  .name.skeleton {
    margin: 0 auto;
    margin-bottom: 1rem !important;
    width: 30%;
  }

  .description.skeleton {
    color: transparent;
    margin-left: 0;
    padding: 0;
    max-width: fit-content;

    @include mixins.mq(s-phone) {
      max-width: 60%;
      white-space: nowrap;
    }
  }

  .icons.skeleton {
    margin-right: 0;
    max-width: 5%;
  }

  .source.skeleton {
    margin-top: 1rem;
    padding-top: 0;
    width: 40%;

    &:nth-child(3) {
      margin-top: 0.5rem;
      width: 60%;
    }

    &:nth-child(4) {
      margin-top: 0.5rem;
      width: 10%;
    }
  }

  .button.skeleton {
    color: transparent;
  }
}