@use "../_globals/mixins";

#result {
  height: auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-size: var(--body-h2-fontsize);
  line-height: 2rem;
  padding-bottom: 1rem;

  @include mixins.mq(phone) {
    font-size: 1rem !important;
    line-height: 1.5rem;
  }

  .resultborder {
    border: 0.01rem solid #ccc;
    padding: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.spinner {
  animation: pulse 0.5s infinite ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.timeout,
.timeout-final {
  font-weight: 600;
  padding-bottom: 1rem;
  color: var(--unknown);
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.timeout span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.timeout span:nth-child(2) {
  animation-delay: 0.2s;
}

.timeout span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Make sure mobile functions are not displayed on desktop*/
.icon-flipcamera {
  @include mixins.mq(desktop) {
    display: none;
  }

  @include mixins.mq(phone) {
    display: inline-block;
  }
}