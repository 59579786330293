@use "../_globals/mixins";

.labels {
  padding: mixins.rem(8px) !important;
  width: mixins.rem(64px) !important;
  height: auto;

  @include mixins.mq(s-phone) {
    padding: 0.5rem !important;
    width: 2.5rem !important;
  }

  @include mixins.mq(phone) {
    width: 3rem !important;
  }
}

/* Main colors for results */
.non-vegan,
.icon-cancle {
  color: var(--non-vegan);
}

.maybe-vegan {
  color: var(--maybe-vegan);
}

.vegan,
.icon-ok {
  color: var(--vegan);
}

.unknown {
  color: var(--unknown);
}

.icon-cancle,
.icon-ok {
  display: inline-block;
}

.nutri_a {
  color: #00bf00;
}

.nutri_b {
  color: #73ff00;
}

.nutri_c {
  color: #fc0;
}

.nutri_d {
  color: #f60;
}

.nutri_e {
  color: #ff1900;
}

.non-vegan,
.nutri_a,
.nutri_b,
.nutri_c,
.nutri_d,
.nutri_e,
.unknown,
.vegan,
.warning {
  display: block !important;
}

.warning {
  @include mixins.mq(phone) {
    font-size: 0.6rem !important;
    line-height: 1rem !important;
  }
}