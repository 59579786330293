@font-face {
  font-family: "vegancheckicons";
  src: url("../font/vegancheckicons.eot?33525214");
  src: url("../font/vegancheckicons.eot?33525214#iefix") format("embedded-opentype"),
    url("../font/vegancheckicons.woff2?33525214") format("woff2"),
    url("../font/vegancheckicons.woff?33525214") format("woff"),
    url("../font/vegancheckicons.ttf?33525214") format("truetype"),
    url("../font/vegancheckicons.svg?33525214#vegancheckicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "additional_icons";
  src: url("../font/additional_icons.eot?64205737");
  src: url("../font/additional_icons.eot?64205737#iefix") format("embedded-opentype"),
    url("../font/additional_icons.svg?64205737#additional_icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nutriscore";
  src: url("../font/nutriscore.eot?4788995906");
  src: url("../font/nutriscore.eot?4788995906#iefix") format("embedded-opentype"),
    url("../font/nutriscore.woff2?4788995906") format("woff2"),
    url("../font/nutriscore.woff?4788995906") format("woff"),
    url("../font/nutriscore.ttf?4788995906") format("truetype"),
    url("../font/nutriscore.svg?4788995906#nutriscore") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "vegancheckicons", "additional_icons", "nutriscore";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ingredients:before {
  content: "\0e90";
}

/* 'ຐ' */
.icon-a:before {
  content: "\e90a";
}

/* '' */
.icon-b:before {
  content: "\e90b";
}

/* '' */
.icon-c:before {
  content: "\e90c";
}

/* '' */
.icon-d:before {
  content: "\e90d";
}

/* '' */
.icon-e:before {
  content: "\e90e";
}

/* '' */
.icon-twitter:before {
  content: "\f099";
}

/* '' */
.icon-facebook:before {
  content: "\f09a";
}

/* '' */
.icon-github-circled:before {
  content: "\f09b";
}

/* '' */
.icon-docs:before {
  content: "\f0c5";
}

/* '' */
.icon-ellipsis:before {
  content: "\f141";
}

/* '' */
.icon-instagram:before {
  content: "\f16d";
}

/* '' */
.icon-paypal:before {
  content: "\f1ed";
}

/* '' */
.icon-whatsapp:before {
  content: "\f232";
}

/* '' */
.icon-credit-card-alt:before {
  content: "\f283";
}

/* '' */
.icon-chat:before {
  content: "\f284";
}

/* '' */
.icon-mail:before {
  content: "\f285";
}

/* '' */
.icon-telegram:before {
  content: "\f286";
}

/* '' */

.icon-block:before {
  content: "\e802";
}

/* '' */
.icon-lock:before {
  content: "\e803";
}

/* '' */
.icon-ok:before {
  content: "\e804";
}

/* '' */
.icon-attention-alt:before {
  content: '\f12a';
}

/* '' */
.icon-barcode:before {
  content: "\e805";
}

/* '' */
.icon-down-open:before {
  content: "\e806";
}

/* '' */
.icon-up-open:before {
  content: "\e807";
}

/* '' */
.icon-left-open:before {
  content: "\e808";
}

/* '' */
.icon-right-open:before {
  content: "\e809";
}

/* '' */
.icon-heart:before {
  content: "\e80a";
  color: #ee5253;
}

/* '' */
.icon-attention:before {
  content: "\e80b";
}

/* '' */
.icon-info-circled:before {
  content: "\e80c";
}

/* '' */
.icon-cancel:before {
  content: "\e80d";
}

/* '' */
.icon-vegancheck:before {
  content: "\e80e";
  color: #ee5253;
}

/* '' */
.icon-off:before {
  content: "\e80f";
}

/* '' */
.icon-pencil:before {
  content: "\e810";
}

/* '' */
.icon-github:before {
  content: "\f09b";
}

/* '' */
.icon-help:before {
  content: "\f128";
}

/* '' */
.icon-info-alt:before {
  content: "\f129";
}

/* '' */
.icon-lock-open-alt:before {
  content: "\f13e";
}

/* '' */

.icon-flash:before {
  content: "\e900";
}

/* '' */
.icon-flipcamera:before {
  content: "\e901";
}

/* '' */
.icon-camera-outline:before {
  content: "\e902";
}

/* '' */
.icon-camera:before {
  content: "\e903";
}

/* '' */

.icon-mastodon:before {
  content: "\f098";
}

/* '' */

.icon-kofi:before {
  content: "\f097";
  margin-right: 0.7rem;
}