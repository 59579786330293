@use "../_globals/mixins";

/* Modals */
.source {
  sup {
    font-size: 100%;
    margin-left: mixins.rem(4.8px);
  }
}

sup {
  margin: mixins.rem(1.6px);
  text-decoration: underline;
  cursor: pointer;
}

.open {
  display: block !important;
}

.modal_view {
  background-color: var(--modal-bg);
  color: var(--modal-fg) !important;
  border-top-right-radius: mixins.rem(8px);
  border-top-left-radius: mixins.rem(8px);
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  height: 94%;
  z-index: 9999999;
  overflow: overlay;
  text-align: left;
  box-shadow: 0 mixins.rem(25px) mixins.rem(25px) mixins.rem(50px) mixins.rem(12px) rgba(0, 0, 0, 0.5);
  padding-bottom: mixins.rem(16px);

  @include mixins.mq(desktop) {
    height: auto;
    max-width: 40%;
    max-height: 50%;
    left: 30%;
    top: 10%;
    border-radius: 0.5rem;
    box-shadow: none;
  }

  .heading_img {
    width: 20%;
    max-width: mixins.rem(48px);
    margin-top: mixins.rem(32px);
    margin-bottom: 0;
  }

  .pwa-install-prompt__guide__icon {
    width: mixins.rem(16px);
    padding-right: mixins.rem(4.8px);
  }

  h2,
  h1 {
    font-weight: 600;
    font-size: mixins.rem(16px);
    margin-top: mixins.rem(24px);
    padding-left: mixins.rem(24px);
    padding-right: mixins.rem(24px);
  }

  h1 {
    font-size: mixins.rem(24px);
    margin-top: mixins.rem(8px);
    color: var(--modal-fg) !important;
    text-align: center !important;
  }

  p {
    color: var(--modal-fg) !important;
    padding-left: mixins.rem(24px);
    padding-right: mixins.rem(24px);
    font-size: var(--body-fontsize);
  }

  a {
    color: var(--modal-fg) !important;
  }

  .button {
    font-size: mixins.rem(16px);
    color: var(--button-fg-re) !important;
    background: var(--button-bg-re);
    padding: mixins.rem(16px);
    padding-left: mixins.rem(16px);
    padding-right: mixins.rem(16px);
    border-radius: mixins.rem(48px);
    margin-top: mixins.rem(12.8px) !important;
    margin-bottom: mixins.rem(16px);
    text-decoration: none;
    display: inline-block;
  }

  .modal_close {
    text-align: left;

    .btn-dark {
      width: mixins.rem(32px);
      height: mixins.rem(32px);
      position: fixed;
      right: mixins.rem(16px);
      margin-top: mixins.rem(12.8px) !important;
      display: inline-block;
      min-width: auto;
      font-weight: 400;
      line-height: 1.67;
      text-align: center;
      text-decoration: none;
      border: none;
      cursor: pointer;
      padding: 0;
      border-radius: 60%;
      color: #000 !important;
      background-color: rgba(204, 204, 204, 0.6);
      font-size: mixins.rem(20.8px);
      z-index: 999999;
      backdrop-filter: blur(mixins.rem(3.2px));

      @include mixins.mq(desktop) {
        right: 31%;
      }
    }
  }

  .share-btn {
    background: var(--modal-full-btn);
    border-radius: mixins.rem(8px);
    padding: mixins.rem(16px);
    margin: mixins.rem(16px);
    display: flex;

    .share-text {
      display: block;
      font-size: mixins.rem(19.2px);
      width: 93%;
    }

    .share-icon {
      width: 7%;
    }
  }

  /* Donation modal */
  .option {
    background: var(--modal-full-btn);
    border: var(--modal-border);
    border-radius: mixins.rem(8px);
    padding: mixins.rem(16px);
    margin: mixins.rem(16px);

    .muted {
      color: #ccc;
      display: block;
      font-size: mixins.rem(12.8px);
      text-transform: uppercase;
      padding-bottom: mixins.rem(16px);
    }

    .price {
      display: block;
      font-size: mixins.rem(19.2px);
    }
  }

  .active {
    border: mixins.rem(1.6px) #016afe solid;
  }

  .center {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  .donate {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: var(--modal-bg-overlay);
    backdrop-filter: blur(mixins.rem(3.2px));

    @include mixins.mq(desktop) {
      position: initial;
    }
  }

  .info {
    color: #9e9e9e;
    font-size: mixins.rem(12.8px);
    display: block;
    margin: mixins.rem(16px);
    margin-top: 0;
  }

  .form-check-input {
    position: absolute;
    right: mixins.rem(32px);
    transform: scale(1.2);

    @include mixins.mq(s-desktop) {
      transform: scale(1.5);
    }

    @include mixins.mq(s-phone) {
      transform: scale(1.2);
    }

    @include mixins.mq(phone) {
      transform: scale(1.5);
    }
  }

  /* Menu */
  .menu {
    background: var(--modal-full-btn);
    padding: mixins.rem(16px);
    margin-left: mixins.rem(16px);
    margin-right: mixins.rem(16px);
    border-radius: 0;
    border-bottom: var(--nav-border);
    display: flex;
    text-decoration: none;

    .label {
      display: block;
      font-size: mixins.rem(19.2px);
      width: 95%;
    }

    .social-icon {
      width: 3%;
      color: var(--modal-fg) !important;
    }
  }

  .twitter {
    border-top-right-radius: mixins.rem(8px);
    border-top-left-radius: mixins.rem(8px);
  }

  .last {
    border-bottom-right-radius: mixins.rem(8px);
    border-bottom-left-radius: mixins.rem(8px);
    border-bottom: none;
  }
}

.info {
  color: #9e9e9e;
  font-size: mixins.rem(12.8px);
  display: block;
  margin-top: 0;
}

.ingredients {
  h2 {
    color: #000 !important;
  }
}

.back {
  position: absolute;
  left: 0;
  top: 0;
  padding: mixins.rem(16px);
  text-decoration: none;
}