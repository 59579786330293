@use 'sass:math';
/* Setup: @include font-size(50px); and for media-queries @include mq(tablet){} */

// converts several units to em (supports px, pt, pc, in, mm, cm)
@function em($size) {
  @if not math.is-unitless($size) {
    @if math.unit($size)==em {
      @return $size;
    }

    @return math.div(0px + $size, 16px) * 1em;
  }

  @return null;
}

// converts several units to rem (supports px, pt, pc, in, mm, cm)
@function rem($size) {
  $size: em($size);

  @if $size {
    @return calc(em($size) / 1em)+rem;
  }

  @return null;
}

// FONT SIZE MIXIN for rem
@mixin font-size($size) {
  font-size: $size;
  font-size: rem($size);
}

// MEDIA QUERY Mixin
@mixin mq($size) {
  @if $size ==phone {
    @media (max-width: 48rem) {
      @content;
    }
  }

  @else if $size ==s-phone {
    @media (max-width: 21.5rem) {
      @content;
    }
  }

  @else if $size ==s-desktop {
    @media (max-width: 69.5rem) {
      @content;
    }
  }

  @else if $size ==desktop {
    @media (min-width: 69.6rem) {
      @content;
    }
  }
}

// COLORSCHEME Mixin
@mixin theme($color) {
  @if $color ==light {
    @media (prefers-color-scheme: light) {
      @content;
    }
  }

  @else if $color ==dark {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }
}