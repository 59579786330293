@use "../_globals/mixins";

/* Selection color */
::-moz-selection {
  background-color: #353b48;
  color: #fff;
}

::selection {
  background-color: #353b48;
  color: #fff;
}

/* Define main body */
body {
  font-family: var(--body-font);
  font-weight: var(--body-fontweight);
  background-color: var(--bg-color);
  color: var(--fg-color);
  font-size: var(--body-fontsize);
  text-align: center;
  word-wrap: break-word;

  @include mixins.theme(light) {
    background-color: #7f8fa6;
  }
}

.modalIsOpen {
  opacity: 0.5;
  transform: scale(0.95);
  animation: 0.5s smallerize;
}

.modalIsClosed {
  opacity: 1;
  transform: scale(1);
  animation: 0.5s biggerize;
}

/* Headings */
h1 {
  color: var(--fg-color);

  @include mixins.mq(phone) {
    font-size: 1.8rem;
  }
}

/* Link styles */
a {
  text-decoration: underline;
  color: var(--link-color);
  transition: 0.8s;
}

a:hover,
a:focus {
  color: var(--link-transition-color);
}

/* Inputs */
fieldset {
  display: flex;
  border: none;
}

legend {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  width: 55%;
  padding: mixins.rem(16px);
  padding-top: mixins.rem(15px);
  text-align: center;
  color: #000;
  font-weight: var(--body-fontweight);
  flex: 1;
  border-top: mixins.rem(0.16px) solid #ccc;
  border-bottom: mixins.rem(0.16px) solid #ccc;
  border-left: none;
  border-right: none;
  border-radius: 0;

  @include mixins.mq(phone) {
    padding-top: mixins.rem(14px);
    padding-bottom: mixins.rem(15px);
  }
}

textarea {
  width: 45%;
  padding-top: 1rem;
  text-align: center;
  color: #000;
  font-weight: var(--body-fontweight);
  flex: 1;
  border-top: mixins.rem(0.16px) solid #ccc;
  border-bottom: mixins.rem(0.16px) solid #ccc;
  border-left: mixins.rem(0.16px) solid #ccc;
  border-right: none;
  border-radius: 0;
  resize: vertical;
}

/* Container */
.container {
  padding-top: mixins.rem(150px);
  /* padding-bottom: rem(50px);*/
  width: 50%;
  margin: 0 auto;

  @include mixins.mq(phone) {
    padding-top: 4rem;
    width: 100%;
  }

  img {
    width: mixins.rem(48px);
    height: mixins.rem(48px);
  }
}

.top {
  @include mixins.mq(phone) {
    padding-top: 0.5rem;
  }

  @include mixins.mq(s-phone) {
    padding-top: 0.5rem;
  }

  @include mixins.mq(s-desktop) {
    padding-top: 2rem;
  }
}

.logo {
  padding-bottom: mixins.rem(32px);

  @include mixins.mq(phone) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

/* Longpage for Privacy/Imprint */
.longpage {
  padding-top: mixins.rem(50px) !important;
  color: #000;

  a,
  h1 {
    color: #000;
  }
}

/* Paragraphs */
p.unknown,
p.missing {
  margin: 0 !important;
}

p {
  line-height: mixins.rem(24px);

  @include mixins.theme(light) {
    color: #fff;
  }
}

/* Footer */
footer {
  font-size: mixins.rem(13.6px);
  padding-bottom: 4.8rem;

  @include mixins.mq(s-phone) {
    font-size: 0.6rem;
    margin-bottom: 1rem;
  }

  @include mixins.mq(phone) {
    font-size: 0.7rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    max-width: 90%;
  }

  a {
    @include mixins.theme(light) {
      color: #fff;
    }
  }

  a:hover,
  a:focus {
    color: #7f8fa6;

    @include mixins.theme(light) {
      color: #fff;
    }
  }
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  color: #000;
}

table tbody,
thead {
  text-align: left;
}