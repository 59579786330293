@use "../_globals/mixins";

/* Data Source label */
.source {
  font-weight: 400;
  font-size: mixins.rem(12.8px);
  padding-top: mixins.rem(16px);
  line-height: mixins.rem(22.4px);
  display: block;

  a {
    font-weight: 400;
    color: #505050;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: #000;
    }
  }
}