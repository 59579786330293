@use "../_globals/mixins";

/* Navbar */
.nav {
  cursor: pointer;
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: blur(mixins.rem(3.2px));
  background: var(--nav-bg);
  width: 100%;
  border-bottom: var(--nav-border);
  text-align: left;
  height: mixins.rem(67.2px);
  font-family: SF Pro Text, system-ui, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Arial, sans-serif;

  @include mixins.mq(s-desktop) {
    bottom: 0%;
    top: initial;
    border-top: var(--nav-border);
    border-bottom: none;
    height: 4.8rem;
  }

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    padding-top: mixins.rem(8px);
    padding-left: mixins.rem(16px);
    padding-right: mixins.rem(16px);
    margin: 0;
    list-style: none;
  }

  .flex-item {
    height: mixins.rem(40px);
    margin: 0;
    padding: mixins.rem(1.6px);
    text-align: center;
    color: #8e8e93;
    font-size: mixins.rem(16px);

    @include mixins.theme(light) {
      color: #000;
    }

    .icon {
      &:before {
        color: #8e8e93 !important;

        @include mixins.theme(light) {
          color: #000;
        }
      }
    }
  }

  .active {
    color: #fff !important;

    @include mixins.theme(light) {
      color: #007aff !important;
    }

    .icon {
      &:before {
        color: #fff !important;

        @include mixins.theme(light) {
          color: #007aff !important;
        }
      }
    }
  }

  .menu-item {
    display: block;
    padding-top: mixins.rem(4.8px);
  }

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    color: inherit;
  }
}