@use "../_globals/mixins";

/* Buttons */
button {
  width: 10%;
  background: transparent;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: mixins.rem(16px);
  text-align: center;
  color: #000;
  font-weight: var(--body-fontweight);
  transition: 0.5s;
  border: mixins.rem(0.16px) solid #ccc;
  height: 4rem;

  @include mixins.mq(s-desktop) {
    width: 15%;
    padding-left: 0;
    padding-right: 0;
  }

  @include mixins.mq(phone) {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
  }
}

button:hover,
button:focus {
  background: #f0f0f0;
}

.btn-dark {
  margin-top: mixins.rem(12.8px) !important;
  display: inline-block;
  min-width: auto;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  border: mixins.rem(1.008px) solid #424242;
  cursor: pointer;
  padding: mixins.rem(4px) mixins.rem(8px);
  font-size: mixins.rem(14px);
  border-radius: mixins.rem(3.2px);
  color: #fff !important;
  background-color: #212529;
  transition: 0.5s;

  @include mixins.mq(s-phone) {
    width: 6.5rem;
    font-size: 0.9rem;
  }

  &:hover,
  &:focus {
    color: #fff;
    border: mixins.rem(1.008px) solid #6b6b6b;
  }

  &:active {
    color: #000;
    background-color: #fff;
  }
}

#tweet {
  margin-right: mixins.rem(3.2px);
}

.nolink {
  text-decoration: none;
}