@use "../_globals/mixins";

/* Open Shortcuts */
#shortcut {
  cursor: pointer;
  color: #0abde3;
  font-weight: bold;
  position: absolute;
  z-index: 999;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--bg-color);
  width: 100%;
  border-bottom: var(--pwa-border);
  text-align: left;
  height: mixins.rem(44.8px);

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    writing-mode: horizontal-tb;
    padding: 0;
    padding-left: mixins.rem(16px);
    padding-right: mixins.rem(16px);
    margin: 0;
    list-style: none;
  }

  .flex-item {
    height: mixins.rem(40px);
    margin: 0;
    padding: 0;
    padding-top: mixins.rem(3.2px);

    &:first-child {
      width: mixins.rem(40px);
      max-width: 20%;
    }

    &:nth-child(2) {
      max-width: 50%;
    }

    &:last-child {
      margin-top: mixins.rem(6.4px);
      margin-left: auto;
      float: right;
      max-width: 35%;
      justify-content: flex-end;
    }
  }

  .heading {
    font-size: mixins.rem(12.8px);
    font-family: SF Pro Text, system-ui, -apple-system, BlinkMacSystemFont,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #fff;

    @include mixins.theme(light) {
      color: #fff;
    }
  }

  .subheading {
    font-size: mixins.rem(11.2px);
    font-family: SF Pro Text, system-ui, -apple-system, BlinkMacSystemFont,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    display: block;
    color: #ccc;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include mixins.theme(light) {
      color: #c0c5d5;
    }
  }

  a {
    text-decoration: none;
  }

  .button {
    font-size: mixins.rem(11.2px);
    color: #fff;
    text-transform: uppercase;
    background: #007aff;
    padding: mixins.rem(8px);
    padding-left: mixins.rem(24px);
    padding-right: mixins.rem(24px);
    border-radius: mixins.rem(48px);

    @include mixins.theme(light) {
      background: #fff;
      color: #7f8fa6;
    }
  }

  img {
    width: mixins.rem(32px);
  }
}

/* Install PWA */
#pwainstall {
  position: absolute;
  z-index: 9999;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--bg-color);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-bottom: var(--pwa-border);
  text-align: left;
  height: mixins.rem(64px);
  display: none;

  @include mixins.mq(s-phone) {
    height: 3.5rem;
  }

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    writing-mode: horizontal-tb;
    padding: 0;
    padding-left: mixins.rem(16px);
    padding-right: mixins.rem(16px);
    margin: 0;
    list-style: none;

    @include mixins.mq(s-phone) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .flex-item {
    height: mixins.rem(40px);
    margin: 0;
    padding: 0;
    padding-top: mixins.rem(4.8px);

    &:first-child {
      font-size: mixins.rem(24px);
      padding-top: mixins.rem(16px);
      padding-right: mixins.rem(8px);
      font-weight: 100;
      color: #ccc;
      opacity: 0.2;
    }

    &:nth-child(3) {
      padding-top: mixins.rem(11.2px);
    }

    &:last-child {
      margin-top: mixins.rem(12px);
      margin-left: auto;
      float: right;
      justify-content: flex-end;
    }
  }

  .heading {
    font-size: mixins.rem(12.8px);
    font-family: SF Pro Text, system-ui, -apple-system, BlinkMacSystemFont,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #fff;
    font-weight: bold;

    @include mixins.mq(s-phone) {
      font-size: 0.65rem;
    }

    @include mixins.theme(light) {
      color: #fff;
    }
  }

  .subheading {
    font-size: mixins.rem(11.2px);
    font-family: SF Pro Text, system-ui, -apple-system, BlinkMacSystemFont,
      Helvetica Neue, Helvetica, Arial, sans-serif;
    display: block;
    color: #ccc;
    font-weight: 400;

    @include mixins.mq(s-phone) {
      font-size: 0.55rem;
    }

    @include mixins.theme(light) {
      color: #c0c5d5;
    }
  }

  a {
    text-decoration: none;
  }

  .button {
    font-size: mixins.rem(11.2px);
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background: #007aff;
    padding: mixins.rem(8px);
    padding-left: mixins.rem(24px);
    padding-right: mixins.rem(24px);
    border-radius: mixins.rem(48px);

    @include mixins.mq(s-phone) {
      font-size: 0.5rem;
    }

    @include mixins.theme(light) {
      background: #fff;
      color: #7f8fa6;
    }
  }

  img {
    width: mixins.rem(51.2px);
    border: mixins.rem(0.8px) grey solid;
    border-radius: mixins.rem(12px);
    margin-right: mixins.rem(8px);

    @include mixins.mq(s-phone) {
      width: 2.8rem;
    }
  }
}