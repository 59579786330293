@use "../_globals/mixins";

/* Scanner */
.eanscanner {
  z-index: 999999 !important;
}

/* Scanner close */
#close {
  cursor: pointer;
  font-weight: bold;
  position: fixed;
  z-index: 99999999999;
  top: 0%;
  transform: translateX(-50%);
  background-color: var(--bg-color);
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
  color: #fff;
  left: 50%;

  @include mixins.mq(phone) {
    width: 101%;
  }

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    writing-mode: horizontal-tb;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-left: mixins.rem(16px);
    margin-right: mixins.rem(16px);
    list-style: none;
  }

  .flex-item {
    margin: 0;
    padding: 0;
    padding-top: mixins.rem(3.2px);
    font-size: mixins.rem(20.8px);

    &:last-child {
      float: right;
      justify-content: flex-end;
    }
  }

  .middle {
    font-size: mixins.rem(16px);
    font-weight: 300;
  }

  .button {
    font-size: mixins.rem(11.2px);
    color: var(--button-fg);
    text-transform: uppercase;
    background: var(--button-bg);
    padding: mixins.rem(8px);
    padding-left: mixins.rem(24px);
    padding-right: mixins.rem(24px);
    border-radius: mixins.rem(48px);
  }
}

.cam_btn {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: mixins.rem(32px);
  font-size: mixins.rem(19.2px);
  z-index: 99999999999999999;
  color: var(--button-fg);
  text-transform: uppercase;
  background: var(--button-bg);
  padding: mixins.rem(8px);
  padding-left: mixins.rem(24px);
  padding-right: mixins.rem(24px);
  border-radius: mixins.rem(48px);
  transition: 0.8s;

  &:hover,
  &:active {
    background: grey;
  }
}

/* Scanner Overlay */
#barcodeicon {
  font-weight: bold;
  position: absolute;
  z-index: 99999999999;
  top: 30%;
  left: 50%;
  opacity: 0.4;
  font-size: mixins.rem(128px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Scanner Underlay */
#background {
  backdrop-filter: blur(mixins.rem(48px));
  z-index: 999;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.name {
  display: block;
  padding-bottom: mixins.rem(16px);

  @include mixins.mq(s-phone) {
    display: inline-block;
  }

  @include mixins.mq(phone) {
    display: inline-block;
  }
}